<template>
  <div class='tinymceClass'>
    <div v-show='locked' v-html='value'></div>
    <div>
      <div :id='tinymceId' v-show='!locked' style='outline: 2px solid #0288d1' />
    </div>
  </div>
</template>

<script>
import load from './Tinymce/dynamicLoadScript'
import { getToken } from '@/utils/auth'
import { getUint8Arr } from '@/utils/image'

export default {
  name: 'Tinymce',
  props: {
    value: {
      type: String,
      default: '<p></p>'
    },
    placeholder: {
      type: String,
      default: '请输入内容'
    }
  },
  data() {
    return {
      hasChange: false,
      hasInit: false,
      tinymceId: 'vue-tinymce-' + +new Date() + ((Math.random() * 1000).toFixed(0) + ''),
      fullscreen: false,
      toolbar: [],
      content: ''
    }
  },
  watch: {
    locked: {
      handler(val) {
        if (val) {
          const tiny = window.tinymce.get(this.tinymceId)
          if (tiny) {
            tiny.mode.set('readonly')
          }
        } else {
          const tiny = window.tinymce.get(this.tinymceId)
          if (tiny) {
            tiny.mode.set('design')
          }
        }
      }
    },
    value() {
      this.setContent(this.value)
    }
  },
  computed: {
    locked() {
      return this.$store.state.locked
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      // dynamic load tinymce from cdn
      if (window.tinymce) {
        this.initTinymce()
      } else {
        load(process.env.VUE_APP_PUBLIC_PATH + '/tinymce/tinymce.min.js', (err) => {
          if (err) {
            console.error(err.message)
            return
          }
          window.tinymce.baseURL = process.env.VUE_APP_PUBLIC_PATH + '/tinymce'
          this.initTinymce()
        })
      }
    },
    initTinymce() {
      if (!window.tinymce) return
      window.tinymce.init({
        selector: `#${this.tinymceId}`,
        language: 'zh_CN',
        height: 300,
        // menubar: this.menubar, //菜单:指定应该出现哪些菜单
        branding: false,
        inline: true,
        toolbar: ['searchreplace undo redo code'], // 分组工具栏控件
        toolbar_location: 'top',
        toolbar_sticky: true,
        // toolbar: false,
        placeholder: this.placeholder,
        // automatic_uploads: false,
        menubar: false,
        quickbars_selection_toolbar:
          'bold alignleft aligncenter alignright outdent indent removeformat subscript superscript bullist numlist charmap table forecolor backcolor',
        quickbars_insert_toolbar: 'quickimage quicktable',
        plugins: [
          'table,link,quickbars',
          'advlist anchor autolink code codesample directionality emoticons fullscreen hr image imagetools importcss insertdatetime link lists media nonbreaking noneditable pagebreak paste preview print save searchreplace tabfocus table template textpattern visualblocks visualchars wordcount'
        ], // 插件(比如: advlist | link | image | preview等)
        // object_resizing: false, // 是否禁用表格图片大小调整
        end_container_on_empty_block: true, // enter键 分块
        powerpaste_word_import: 'merge', // 是否保留word粘贴样式  clean | merge
        code_dialog_height: 450, // 代码框高度 、宽度
        code_dialog_width: 1000,
        advlist_bullet_styles: 'square', // 无序列表 有序列表
        // content_style: 'img {width:900px !important }', // 初始化赋值
        contextmenu: false, // 鼠标右键菜单
        paste_data_images: true, // 设置为“true”将允许粘贴图像，而将其设置为“false”将不允许粘贴图像。
        init_instance_callback: (editor) => {
          editor.setContent(this.value ? this.value : '<p></p>')
          if (this.locked) {
            editor.mode.set('readonly')
          }
          editor.on('blur', (e) => {
            console.log(e)
            this.$emit('input', e.target.getContent())
          })
        },
        urlconverter_callback: (url, node, onSave, name) => {
          return url
        },
        images_upload_handler: async (blobInfo, success) => {
          console.log('图片压缩')
          const img = new Image()
          const canvas = document.createElement('canvas')
          const drawer = canvas.getContext('2d')
          //图片压缩转码
          img.onload = async () => {
            canvas.width = 500
            canvas.height = (img.height / img.width) * canvas.width
            drawer.drawImage(img, 0, 0, canvas.width, canvas.height)
            const dataurl = canvas.toDataURL('image/png', 1)
            const uint8 = getUint8Arr(dataurl)
            const file = new File([uint8.u8arr], 'file.png', { type: uint8.mime })
            var formData = new FormData()
            formData.append('file', file)
            formData.append('SaveDir', 'Map/MapItem')
            this.$dialog.withLoading({ hideOverlay: false }, async () => {
              const res = await this.$ajax({
                url: `${process.env.VUE_APP_API_BASE_URL}file`,
                method: 'post',
                headers: { Authorization: 'Bearer ' + getToken(), 'Content-Type': 'application/json' },
                data: formData,
                dataType: 'json',
                async: false
              })
              const filePath = res.data.data.fileUrl
              console.log('filePath', filePath)
              success(filePath)
            })
          }
        }
      })
    },
    setContent(value) {
      const tiny = window.tinymce.get(this.tinymceId)
      if (tiny) {
        tiny.setContent(value)
      }
    },
    getContent() {
      if (!window.tinymce) return
      const tiny = window.tinymce.get(this.tinymceId)
      if (tiny) {
        tiny.getContent()
      }
    },
    destroyTinymce() {
      if (!window.tinymce) return
      const tinymce = window.tinymce.get(this.tinymceId)
      if (tinymce) {
        tinymce.destroy()
      }
    }
  },
  activated() {
    if (window.tinymce) {
      this.initTinymce()
    }
  },
  deactivated() {
    this.destroyTinymce()
  },
  destroyed() {
    this.destroyTinymce()
  }
}
</script>
<style lang='scss'>
.tinymceClass {
  .mce-content-body::before {
    text-indent: 2em;
    line-height: 50px;
  }
}
</style>
