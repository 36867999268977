<template>
  <g
    v-if="
      lineProps.isHide !== true &&
      isAllowShowNode(lineProps.fromNode) &&
      isAllowShowNode(lineProps.toNode) &&
      isLineShow(clips, lineProps)
    "
    ref="seeksRGLink"
    transform="translate(0,0)"
  >
    <template v-for="(thisRelation, ri) in lineProps.relations">
      <g v-if="thisRelation.isHide === false" :key="'l-' + thisRelation.id">
        <path
          :d="
            createLinePath(
              lineProps.fromNode,
              lineProps.toNode,
              ri,
              thisRelation,
              clips,
              after,
              allLines,
              allNodes,
              lineProps
            )
          "
          :class="[thisRelation.styleClass]"
          :stroke-dasharray="thisRelation.lineShape === 12 ? '5,5' : ''"
          :stroke="thisRelation.lineShape === 10 ? 'red' : 'black'"
          :stroke-width="thisRelation.lineShape === 13 ? '5px' : '2px'"
          fill="none"
          :marker-end="getArrow(thisRelation.isHideArrow, thisRelation.lineShape)"
          @click="onClickLine($event)"
        />
        <g
          v-if="graphSetting.defaultShowLineLabel && thisRelation.isHide === false && isLineShow(clips, lineProps)"
          :transform="
            getTextTransform(
              thisRelation,
              thisRelation.textPositon.x,
              thisRelation.textPositon.y,
              thisRelation.textPositon.rotate
            )
          "
        >
          <rect
            :x="-(measureText(thisRelation.text) + 20) / 2 - 10"
            :y="-16"
            :width="measureText(thisRelation.text) + 20"
            height="20"
            style="fill: rgba(255, 127, 80, 0.8)"
          >
          </rect>
          <text
            :key="'t-' + thisRelation.id"
            :x="-(measureText(thisRelation.text) + 20) / 2"
            :y="0"
            :style="{
              fill: thisRelation.fontColor
                ? thisRelation.fontColor
                : thisRelation.color
                ? thisRelation.color
                : undefined,
            }"
            class="c-rg-link-text"
            @click="onClickLabel($event)"
          >
            <!--<textPath :xlink:href="'#'+lineProps.id">{{ lineProps.text }}</textPath>-->
            {{ thisRelation.text }}
          </text>
        </g>
      </g>
    </template>
  </g>
</template>

<script>
// import SeeksRGStore from './SeeksRGStore'
import { lineShapeList } from '../../../../store/default'
import SeeksGraphMath from './SeeksGraphMath'
// import Velocity from 'velocity-animate'
// import { mapState } from 'vuex'
// var _parent = this.$parent
const JUNCTION_POINT_STYLE = {
  border: 'border',
  ltrb: 'ltrb',
  tb: 'tb',
  lr: 'lr',
}
export default {
  name: 'SeeksRGLink',
  props: {
    clips: {
      type: Array,
      default: () => {},
    },
    after: {
      type: Boolean,
      default: false,
    },
    allLines: {
      type: Array,
      default: () => {},
    },
    allNodes: {
      type: Array,
      default: () => {},
    },
    allClippedNodeIds: {
      type: Array,
      default: () => {},
    },
    graphSetting: {
      mustUseProp: true,
      default: () => {
        return {}
      },
      type: Object,
    },
    lineProps: {
      mustUseProp: true,
      default: () => {
        return {}
      },
      type: Object,
    },
    onLineClick: {
      mustUseProp: false,
      default: () => {
        return () => {}
      },
      type: Function,
    },
    onLabelClick: {
      mustUseProp: false,
      default: () => {
        return () => {}
      },
      type: Function,
    },
    dragable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      is_flashing: false,
      canvas: document.createElement('Canvas').getContext('2d'),
    }
  },
  show() {
    this.isShow = true
  },
  watch: {},
  mounted() {
    // this.refresh()
    // var __this = this
    // setInterval(this.onLineClick, 1000)
  },
  // beforeDestroy() {
  //   const elx = this.$refs.seeksRGLink
  //   elx.remove()
  // },
  methods: {
    getTextTransform(thisRelation, x, y, rotate) {
      // console.log(thisRelation, x, y, rotate)
      if (!x || !y) {
        return 'translate(0,0)'
      }
      var __lineShape =
        thisRelation.lineShape === undefined ? this.graphSetting.defaultLineShape : thisRelation.lineShape
      if (__lineShape === 1 || __lineShape === 4) {
        return 'translate(' + x + ',' + y + ')'
      } else {
        return 'translate(' + x + ',' + y + ')'
      }
    },
    getArrow(isHideArrow, lineShape) {
      // console.log('xxxxxxxxxxxx')
      if (isHideArrow) {
        return 'none'
      } else {
        var _arrow = this.$parent.getLineArrow(lineShape)
        return 'url("#' + _arrow + '")'
      }
    },
    createLinePath(from, to, ri, relationData, clips, after, allLines, allNodes, lineProps) {
      // console.log(relationData.rule, from.id, clips, after)
      // 当前line的相关clip
      const clip = clips?.find((clip) => clip.id == from.id || clip.id == to.id)

      // 裁剪规则C：根据裁剪变换relation的from和to
      if (relationData.rule == '裁剪规则C') {
        // 从clipNode出发的line
        if (after && clip && clip.id == from.id) {
          const clipNode = allNodes?.find((node) => node.id == clip.id)
          const newNode = allNodes?.find((node) => node.id == relationData.new)
          // console.log('裁剪规则C', clipNode, newNode)
          from = newNode
        }
      }
	  if(!from){
		  return
	  }
      // 同样两个节点有多个关系时，计算其索引以便错开距离
      const myLines = allLines.filter(
        (line) =>
          (line.fromNode.id == from.id && line.toNode.id == to.id) ||
          (line.fromNode.id == to.id && line.toNode.id == from.id)
      )
      const index = myLines.findIndex((line) => lineProps.seeks_id == line.seeks_id)
      ri = index
      if (ri < 0) ri = myLines.length
      ri = ri - 0.5
      // console.log('myLines', index, ri, myLines)
      // console.log('Relation Index', myLines, lineProps, index, ri, offset)

      // 起点终点位置，功能文字位置等值的确定
      var __lineShape =
        relationData.lineShape === undefined ? this.graphSetting.defaultLineShape : relationData.lineShape
      __lineShape = lineShapeList[relationData.lineShapeType]
      var __lineDirection
      if (__lineShape == 41) {
        __lineShape = 4
        __lineDirection = 'v'
      }
      if (__lineShape == 42) {
        __lineShape = 4
        __lineDirection = 'h'
      }

      var from_x = from.x
      var from_y = from.y
      var to_x = to.x
      var to_y = to.y
      if (isNaN(from_x) || isNaN(from_y)) {
        console.error('error start node:', from)
        relationData.textPositon.x = 50
        relationData.textPositon.y = 50
        relationData.textPositon.rotate = 0
        return 'M 0 0 L 100 100'
      }
      if (isNaN(to_x) || isNaN(to_y)) {
        console.error('error start point:', from)
        relationData.textPositon.x = 50
        relationData.textPositon.y = 50
        relationData.textPositon.rotate = 0
        return 'M 0 0 L 100 100'
      }
      var f_W = from.el.offsetWidth || from.width || from.w
      var f_H = from.el.offsetHeight || from.height || from.h
      if (isNaN(f_W) || isNaN(f_H)) {
        // console.log('error from node size:', f_W, f_H)
        relationData.textPositon.x = 50
        relationData.textPositon.y = 50
        relationData.textPositon.rotate = 0
        return 'M 0 0 L 100 100'
      }
      var t_W = to.el.offsetWidth || to.width || to.w
      var t_H = to.el.offsetHeight || to.height || to.h
      if (isNaN(t_W) || isNaN(t_H)) {
        // console.log('error to node size:', f_W, f_H)
        relationData.textPositon.x = 50
        relationData.textPositon.y = 50
        relationData.textPositon.rotate = 0
        return 'M 0 0 L 100 100'
      }
      if (relationData.isReverse) {
        const [from_x, from_y, to_x, to_y, f_W, f_H, t_W, t_H] = [to_x, to_y, from_x, from_y, t_W, t_H, f_W, f_H]
      }

      // const dis = Math.abs(to_x - from_x) > Math.abs(to_y - from_y)
      // var __lineDirection = dis > 0 ? 'h' : 'v'
      // if (__lineDirection === 'h') {
      //   var __shouldShift = Math.abs(to_y - from_y) > Math.abs(to_x - from_x) / 2
      // } else {
      //   var __shouldShift = Math.abs(to_x - from_x) > Math.abs(to_y - from_y) / 2
      // }

      var __params4start = [
        from_x,
        from_y,
        to_x,
        to_y,
        f_W,
        f_H,
        t_W,
        t_H,
        this.graphSetting.defaultNodeShape,
        relationData.isReverse,
        myLines.length,
        ri,
        __lineDirection,
        __lineShape,
        true,
      ]
      var __params4end = [
        to_x,
        to_y,
        from_x,
        from_y,
        t_W,
        t_H,
        f_W,
        f_H,
        this.graphSetting.defaultNodeShape,
        !relationData.isReverse,
        myLines.length,
        ri,
        __lineDirection,
        __lineShape,
        false,
      ]
      var __start, __end
      var _junctionPointStyle = this.graphSetting.defaultJunctionPoint
      if (!_junctionPointStyle) {
        _junctionPointStyle = JUNCTION_POINT_STYLE.border
      }

      __start = SeeksGraphMath.getBorderPoint4MultiLine(...__params4start)
      __end = SeeksGraphMath.getBorderPoint4MultiLine(...__params4end)

      const [vect_x, vect_y] = [__end.x - __start.x, __end.y - __start.y]
      const norm_y = Math.sqrt(1 / (Math.pow(vect_y / vect_x, 2) + 1)) || 0
      const norm_x = (-norm_y * vect_y) / vect_x || 0
      __start.x = __start.x + norm_x * 15 * ri
      __start.y = __start.y + norm_y * 15 * ri
      __end.x = __end.x + norm_x * 15 * ri
      __end.y = __end.y + norm_y * 15 * ri

      var fx = __start.x
      var fy = __start.y
      var tx = __end.x
      var ty = __end.y
      if (isNaN(fx) || isNaN(fy)) {
        console.error('error start point:', from)
        relationData.textPositon.x = 50
        relationData.textPositon.y = 50
        relationData.textPositon.rotate = 0
        return 'M 0 0 L 100 100'
      }
      if (isNaN(tx) || isNaN(ty)) {
        console.error('error end point:', to)
        relationData.textPositon.x = 50
        relationData.textPositon.y = 50
        relationData.textPositon.rotate = 0
        return 'M 0 0 L 100 100'
      }
      var __buff_x = __end.x - __start.x
      var __buff_y = __end.y - __start.y
      var __buff_type = __end.x > __start.x ? 1 : -1

      if (__lineDirection === 'v') {
        __buff_type = __end.y > __start.y ? 1 : -1
      }
      var __path = ''

      if (after && clip && relationData.rule == '裁剪规则B') {
        // 画圈
        console.log('裁剪规则B', from.id, to.id, ri, relationData, clips,clip)
        if (clip.id == from.id) {
          const coff = 0.5
          const [vect_x, vect_y] = [tx - fx, ty - fy]

          // 求中点用于显示关系文字
          relationData.textPositon.x = fx + vect_x / 2
          relationData.textPositon.y = fy + vect_y / 2
          const norm_y = Math.sqrt(1 / (Math.pow(vect_y / vect_x, 2) + 1))
          const norm_x = (-norm_y * vect_y) / vect_x

          // __path = `M ${tx},${ty} A 1,1 0 1 0 ${(fx + tx) * coff},${(fy + ty) * coff} A 1,1 0 1 0 ${tx},${ty}`
          // relationData.textPositon.x = (fx + tx) * coff
          // relationData.textPositon.y = (fy + ty) * coff
          // relationData.textPositon.rotate = 0

          __path = `M ${tx+10},${ty} 
					 Q ${fx + (vect_x / 4) * 3 + norm_x * 70},${fy + (vect_y / 4) * 3 + norm_y * 70},${fx + vect_x / 2},${fy + vect_y / 2}`
          __path += `Q ${fx + (vect_x / 4) * 3 - norm_x * 70},${fy + (vect_y / 4) * 3 - norm_y * 70},${tx},${ty}`
        }
      } else if (__lineShape === 4) {
        // 折线
        const distanceRate = 0 //(60 / (this.lineProps.relations.length + 1)) * (ri + 1) - 30
        if (__lineDirection === 'v') {
          // __buff_y = __buff_y - (__buff_type * 33 + distanceRate)
          relationData.textPositon.x = fx //  + __buff_x + 5
          relationData.textPositon.y = fy + __buff_y / 2
          relationData.textPositon.rotate = 90
          __path = 'M ' + fx + ' ' + fy + ' v' + (__buff_y + distanceRate) + ' h' + (__buff_x + distanceRate)
        } else {
          // if (relationData.reverseText === true) {
          //   relationData.textPositon.x = fx + __buff_type * 10 - (__buff_type < 0 ? 30 : 0)
          //   relationData.textPositon.y = fy - 5
          //   //__buff_x = __buff_x - __buff_type * 120
          //   __path = 'M ' + fx + ' ' + fy + ' h' + __buff_x + ' v' + __buff_y
          // } else {
          relationData.textPositon.x = fx + __buff_x / 2
          relationData.textPositon.y = fy // + __buff_y - 5// + distanceRate
          //__buff_x = __buff_x - (__buff_type * 33 + distanceRate)
          // __buff_y = __buff_y + __buff_type * distanceRate
          __path = 'M ' + fx + ' ' + fy + ' h' + __buff_x + ' v' + __buff_y
          // }
        }
      } else if (__lineShape === 2) {
        // var __buff_type_x = __end.x > __start.x ? 1 : -1
        const __buff_type_y = __end.y > __start.y ? 1 : -1
        const _base = Math.abs(__buff_x) + Math.abs(__buff_y)
        relationData.textPositon.x = parseInt(__end.x - (__buff_x / _base) * 60 - 20)
        relationData.textPositon.y = parseInt(__end.y - (__buff_y / _base) * 60 - 20 * __buff_type_y)
        const distanceRate = (1 / (this.lineProps.relations.length + 1)) * (ri + 1) - 0.5 + 0.5
        if (__lineDirection === 'v') {
          __path =
            'M' +
            fx +
            ',' +
            fy +
            ' c' +
            0 +
            ',' +
            __buff_type * 30 +
            ' ' +
            __buff_x * distanceRate +
            ',' +
            __buff_type * -10 +
            ' ' +
            __buff_x +
            ',' +
            __buff_y
        } else {
          __path =
            'M' +
            fx +
            ',' +
            fy +
            ' c' +
            __buff_type * 30 +
            ',' +
            0 +
            ' ' +
            __buff_type * -10 +
            ',' +
            __buff_y * distanceRate +
            ' ' +
            __buff_x +
            ',' +
            __buff_y
        }
      } else if (__lineShape === 6) {
        // const __buff_type_x = __end.x > __start.x ? 1 : -1
        const __buff_type_y = __end.y > __start.y ? 1 : -1
        const _base = Math.abs(__buff_x) + Math.abs(__buff_y)
        relationData.textPositon.x = parseInt(__end.x - (__buff_x / _base) * 60 - 20)
        relationData.textPositon.y = parseInt(__end.y - (__buff_y / _base) * 60 - 20 * __buff_type_y)
        if (__lineDirection === 'v') {
          __path =
            'M' +
            fx +
            ',' +
            fy +
            ' c' +
            0 +
            ',' +
            __buff_y / 2 +
            ' ' +
            __buff_x +
            ',' +
            __buff_y / 2 +
            ' ' +
            __buff_x +
            ',' +
            __buff_y
        } else {
          __path =
            'M' +
            fx +
            ',' +
            fy +
            ' c' +
            __buff_x / 2 +
            ',' +
            0 +
            ' ' +
            __buff_x / 2 +
            ',' +
            __buff_y +
            ' ' +
            __buff_x +
            ',' +
            __buff_y
        }
      } else if (__lineShape === 3) {
        relationData.textPositon.x = __end.x - __buff_type * 63
        relationData.textPositon.y = __end.y + 3
        const distanceRate = (1 / (this.lineProps.relations.length + 1)) * (ri + 1) - 0.5 + 0.5
        if (__lineDirection === 'v') {
          __path =
            'M' +
            fx +
            ',' +
            fy +
            ' c' +
            0 +
            ',' +
            __buff_y * distanceRate +
            ' ' +
            0 +
            ',' +
            0 +
            ' ' +
            __buff_x +
            ',' +
            __buff_y
        } else {
          // console.log('start:', __start, __end, __buff_x, __buff_y)
          __path =
            'M' +
            fx +
            ',' +
            fy +
            ' c' +
            __buff_type * 30 +
            ',' +
            0 +
            ' ' +
            __buff_type * -10 +
            ',' +
            __buff_y * distanceRate +
            ' ' +
            __buff_x +
            ',' +
            __buff_y
        }
      } else if (__lineShape === 5) {
        // relationData.text.x = __start.x + __buff_x / 2 - 33
        // relationData.text.y = __start.y + __buff_y / 2 - 3
        relationData.textPositon.x = __end.x - __buff_type * 63
        relationData.textPositon.y = __end.y + 3
        const distanceRate = (1 / (this.lineProps.relations.length + 1)) * (ri + 1) - 0.5 + 0.5
        if (__lineDirection === 'v') {
          __path =
            'M' +
            fx +
            ',' +
            fy +
            ' c' +
            0 +
            ',' +
            0 +
            ' ' +
            0 +
            ',' +
            __buff_y * distanceRate +
            ' ' +
            __buff_x +
            ',' +
            __buff_y // 鱼尾
        } else {
          __path =
            'M' +
            fx +
            ',' +
            fy +
            ' c' +
            0 +
            ',' +
            0 +
            ' ' +
            __buff_x * distanceRate +
            ',' +
            0 +
            ' ' +
            __buff_x +
            ',' +
            __buff_y // 鱼尾
        }
        // __path = 'M' + fx + ',' + fy + ' c' + (0) + ',' + (0) + ' ' + (0) + ',' + (__buff_y * 0.5) + ' ' + __buff_x + ',' + __buff_y
        // __path = 'M' + fx + ',' + fy + ' c' + (0) + ',' + (0) + ' ' + (-100) + ',' + (__buff_y * 0.5) + ' ' + __buff_x + ',' + __buff_y
        // __path = 'M' + fx + ',' + fy + ' c' + (30) + ',' + (0) + ' ' + (-10) + ',' + (__buff_y * 0.5) + ' ' + __buff_x + ',' + __buff_y
        // __path = 'M' + fx + ',' + fy + ' c' + (50) + ',' + (0) + ' ' + (-50) + ',' + (__buff_y * 0.5) + ' ' + __buff_x + ',' + __buff_y
        // __path = 'M' + fx + ',' + fy + ' c' + (100) + ',' + (0) + ' ' + (10) + ',' + (__buff_y * 0.5) + ' ' + __buff_x + ',' + __buff_y
        // __path = 'M' + fx + ',' + fy + ' c' + (0) + ',' + (0) + ' ' + (__buff_x * 0.5) + ',' + (0) + ' ' + __buff_x + ',' + __buff_y // 类似鱼尾
        // __path = 'M' + fx + ',' + fy + ' c' + (__buff_x * 0.5) + ',' + (0) + ' ' + (0) + ',' + (0) + ' ' + __buff_x + ',' + __buff_y // 三角
        // __path = 'M' + fx + ',' + fy + ' c' + (0) + ',' + (0) + ' ' + (__buff_x * 0.5) + ',' + (0) + ' ' + __buff_x + ',' + __buff_y // 鱼尾
        // __path = 'M' + fx + ',' + fy + ' c' + (50) + ',' + (__buff_y * 0.5) + ' ' + (0) + ',' + (0) + ' ' + __buff_x + ',' + __buff_y //
        // __path = 'M' + fx + ',' + fy + ' c' + (50) + ',' + (__buff_y * 0.5) + ' ' + (0) + ',' + (0) + ' ' + __buff_x + ',' + __buff_y
      }
      // else if (__lineShape === 10) {
      //   // 波浪
      //   // const _angle_type = SeeksGraphMath.getAngleType(__buff_x, __buff_y)
      //   relationData.textPositon.rotate = SeeksGraphMath.getTextAngle(fx, fy, tx, ty)
      //   // const _xxx = _angle_type === 2 || _angle_type === 4 ? -1 : 1
      //   // const _x = (__buff_y === 0 ? -50 : (Math.sin(Math.atan(__buff_x / __buff_y)) * 10) / Math.sin(90)) * _xxx
      //   // const _y = __buff_x === 0 ? -50 : (Math.sin(Math.atan(__buff_y / __buff_x)) * 10) / Math.sin(90)
      //   // relationData.textPositon.x = parseInt(__start.x + __buff_x / 2 - _x)
      //   // relationData.textPositon.y = parseInt(__start.y + __buff_y / 2 - _y)
      //   if (isNaN(relationData.textPositon.rotate)) {
      //     relationData.textPositon.rotate = 0
      //     console.log('NaN rotate:', relationData)
      //   }
      //   // this.lineProps.text = relationData.text.rotate

      //   const [vect_x, vect_y] = [tx - fx, ty - fy]

      //   // 求中点用于显示关系文字
      //   relationData.textPositon.x = fx + vect_x / 2
      //   relationData.textPositon.y = fy + vect_y / 2

      //   const norm_y = Math.sqrt(1 / (Math.pow(vect_y / vect_x, 2) + 1))
      //   const norm_x = (-norm_y * vect_y) / vect_x
      //   const mod = Math.abs(Math.sqrt(Math.pow(vect_x, 2) + Math.pow(vect_y, 2)))
      //   const [norm_line_x, norm_line_y] = [vect_x / mod, vect_y / mod]

      //   const sub = 5
      //   const count = mod / sub

      //   __path = ' M ' + fx + ' ' + fy

      //   __path += ' L ' + (fx + norm_line_x * sub * (count / 4 - 1)) + ' ' + (fy + norm_line_y * sub * (count / 4 - 1))

      //   let j = 1
      //   for (let i = count / 4; i <= (count / 4) * 3 + 1; i += 2) {
      //     j *= -1
      //     const control_x = fx + norm_line_x * sub * (i - 1) + j * norm_x * 10
      //     const control_y = fy + norm_line_y * sub * (i - 1) + j * norm_y * 10
      //     const end_x = fx + norm_line_x * sub * i
      //     const end_y = fy + norm_line_y * sub * i

      //     __path += ` C ${control_x} ${control_y},${control_x} ${control_y},${end_x} ${end_y} `
      //   }

      //   __path += ' L ' + (fx + norm_line_x * sub * count) + ' ' + (fy + norm_line_y * sub * count)

      //   // console.log('svg draw line', __path)
      // }
      // else if (__lineShape === 13) {
      //   // 双线
      //   // const _angle_type = SeeksGraphMath.getAngleType(__buff_x, __buff_y)
      //   relationData.textPositon.rotate = SeeksGraphMath.getTextAngle(fx, fy, tx, ty)
      //   // const _xxx = _angle_type === 2 || _angle_type === 4 ? -1 : 1
      //   // const _x = (__buff_y === 0 ? -50 : (Math.sin(Math.atan(__buff_x / __buff_y)) * 10) / Math.sin(90)) * _xxx
      //   // const _y = __buff_x === 0 ? -50 : (Math.sin(Math.atan(__buff_y / __buff_x)) * 10) / Math.sin(90)
      //   // relationData.textPositon.x = parseInt(__start.x + __buff_x / 2 - _x)
      //   // relationData.textPositon.y = parseInt(__start.y + __buff_y / 2 - _y)
      //   if (isNaN(relationData.textPositon.rotate)) {
      //     relationData.textPositon.rotate = 0
      //     console.log('NaN rotate:', relationData)
      //   }
      //   // this.lineProps.text = relationData.text.rotate

      //   // 求垂直单位向量，解方程
      //   // ax + by = 0
      //   // a^2 + b^2 = 1
      //   // a =  -by / x
      //   // b^2 * y^2 / x^2 + b^2 = 1
      //   // b^2  = 1 / (y^2 / x^2 + 1)

      //   const [vect_x, vect_y] = [tx - fx, ty - fy]

      //   // 求中点用于显示关系文字
      //   relationData.textPositon.x = fx + vect_x / 2
      //   relationData.textPositon.y = fy + vect_y / 2

      //   const norm_y = Math.sqrt(1 / (Math.pow(vect_y / vect_x, 2) + 1))
      //   const norm_x = (-norm_y * vect_y) / vect_x

      //   const fx_new_1 = fx + norm_x
      //   const fy_new_1 = fy + norm_y
      //   const tx_new_1 = tx + norm_x
      //   const ty_new_1 = ty + norm_y

      //   const fx_new_2 = fx - norm_x
      //   const fy_new_2 = fy - norm_y
      //   const tx_new_2 = tx - norm_x
      //   const ty_new_2 = ty - norm_y

      //   const mod = Math.abs(Math.sqrt(Math.pow(vect_x, 2) + Math.pow(vect_y, 2)))
      //   const [norm_line_x, norm_line_y] = [vect_x / mod, vect_y / mod]

      //   __path = `M ${fx_new_1} ${fy_new_1} L ${tx_new_1} ${ty_new_1} M ${fx_new_2} ${fy_new_2} L ${tx_new_2} ${ty_new_2} M ${fx} ${fy} L ${fx + norm_line_x} ${fy + norm_line_y} M ${tx} ${ty} L ${tx + norm_line_x} ${ty + norm_line_y}`

      //   // console.log('svg draw line', __path)
      // }
      else {
        // const _angle_type = SeeksGraphMath.getAngleType(__buff_x, __buff_y)
        relationData.textPositon.rotate = SeeksGraphMath.getTextAngle(fx, fy, tx, ty)
        // const _xxx = _angle_type === 2 || _angle_type === 4 ? -1 : 1
        // const _x = (__buff_y === 0 ? -50 : (Math.sin(Math.atan(__buff_x / __buff_y)) * 10) / Math.sin(90)) * _xxx
        // const _y = __buff_x === 0 ? -50 : (Math.sin(Math.atan(__buff_y / __buff_x)) * 10) / Math.sin(90)
        // relationData.textPositon.x = parseInt(__start.x + __buff_x / 2 - _x)
        // relationData.textPositon.y = parseInt(__start.y + __buff_y / 2 - _y)
        if (isNaN(relationData.textPositon.rotate)) {
          relationData.textPositon.rotate = 0
          console.log('NaN rotate:', relationData)
        }

        const [vect_x, vect_y] = [tx - fx, ty - fy]

        // 求中点用于显示关系文字
        relationData.textPositon.x = fx + vect_x / 4
        relationData.textPositon.y = fy + vect_y / 4

        // this.lineProps.text = relationData.text.rotate
        __path = 'M ' + fx + ' ' + fy + ' L ' + tx + ' ' + ty
      }
      return __path
    },
    onClickLine(e) {
      if (!this.dragable) {
        return
      }
      // RGStore.commit('setCurrentLineId', this.lineProps.id)
      this.graphSetting.checkedLineId = this.lineProps.seeks_id
      // this.lineProps.fromNode.selected = true
      // this.lineProps.toNode.selected = true
      // // Velocity(this.$refs.seeksRGLink, { strokDashoffset: 50 }, { duration: 3000, loop: 5 })
      // setTimeout(
      //   function () {
      //     this.lineProps.fromNode.selected = false
      //     this.lineProps.toNode.selected = false
      //   }.bind(this),
      //   2000
      // )
      if (this.onLineClick) {
        this.onLineClick(this.lineProps, e)
      }
    },
    onClickLabel(e) {
      if (!this.dragable) {
        return
      }
      // RGStore.commit('setCurrentLineId', this.lineProps.id)
      this.graphSetting.checkedLineId = this.lineProps.seeks_id
      // this.lineProps.fromNode.selected = true
      // this.lineProps.toNode.selected = true
      // // Velocity(this.$refs.seeksRGLink, { strokDashoffset: 50 }, { duration: 3000, loop: 5 })
      // setTimeout(
      //   function () {
      //     this.lineProps.fromNode.selected = false
      //     this.lineProps.toNode.selected = false
      //   }.bind(this),
      //   2000
      // )
      if (this.onLineClick) {
        this.onLabelClick(this.lineProps, e)
      }
    },
    isAllowShowNode: function (thisNode) {
      const _r =
        thisNode.isShow !== false &&
        thisNode.isHide !== true &&
        (!thisNode.lot.parent || this.isAllowShowNode(thisNode.lot.parent, false) === true)
      // if (derict !== false && _r === false) console.log('be hide node:', thisNode.text)
      return _r
    },
    isLineShow(clips, lineProps) {
      // 非裁剪后图，显示
      if (!this.after) return true
      // 没有裁剪，显示
      if (!this.clips) return true
      // 裁剪未涉及from和to，显示
      const clip = this.clips.find(
        (clip) => clip.id == this.lineProps.fromNode.id || clip.id == this.lineProps.toNode.id
      )

      // 裁剪后指向裁剪目标Node的连线，不显示
      if (clip && this.allClippedNodeIds.includes(this.lineProps.toNode.id)) {
        return false
      }

      const relation = lineProps.relations[0]
      if (!relation) return true
      // 裁剪规则A：相关所有连线，不显示
      if (relation.rule == '有害功能') {
        if (clip && (clip.id == this.lineProps.fromNode.id || clip.id == this.lineProps.toNode.id)) {
          return false
        }
      }
      if (relation.rule == '裁剪规则A') {
        if (clip && (clip.id == this.lineProps.fromNode.id || clip.id == this.lineProps.toNode.id)) {
          return false
        }
      }
      // 裁剪规则B：指向裁剪目标的连线，不显示
      if (relation.rule == '裁剪规则B') {
        if (clip && clip.id == this.lineProps.toNode.id) {
          return false
        }
      }
      if (relation.rule == '裁剪规则C') {
        // console.log('isLineShow', this.allClippedNodeIds, this.lineProps.toNode.id)
        // 裁剪规则C：指向裁剪目标的连线，不显示
        if (clip && clip.id == this.lineProps.toNode.id) {
          return false
        }
        // 裁剪规则C：新载体是自己，不显示
        if (clip && relation.new == clip.id) {
          return false
        }
      }
      return true
    },
    flash() {},
    measureText(text) {
      this.canvas.font = '14px Noto Serif SC'
      return this.canvas.measureText(text).width
    },
  },
}
</script>

<style type="">
/*.RGLine-enter-active {*/
/*transition: all .3s ease;*/
/*}*/
/*.RGLine-leave-active {*/
/*transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);*/
/*}*/
.c-rg-link-text {
  fill: #fff;
  font-size: 14px;
}
.c-rg-line {
  z-index: 1000;
  fill-rule: nonzero;
  /*marker-end: url('#arrow');*/
  /* firefox bug fix - won't rotate at 90deg angles */
  /*-moz-transform: rotate(-89deg) translateX(-190px);*/
  /*animation-timing-function:linear;*/
  /*animation: ACTRGLineInit 1s;*/
}
.c-rg-line-tool {
  stroke-dasharray: 5, 5;
}
.c-rg-line-flash {
  /* firefox bug fix - won't rotate at 90deg angles */
  -moz-transform: rotate(-89deg) translateX(-190px);
  animation-timing-function: linear;
  animation: ACTRGLineChecked 10s;
}
@keyframes ACTRGLineInit {
  from {
    stroke-dashoffset: 10px;
    stroke-dasharray: 20, 20, 20;
  }

  to {
    stroke-dashoffset: 0;
    stroke-dasharray: 0, 0, 0;
  }
}
@keyframes ACTRGLineChecked {
  from {
    stroke-dashoffset: 352px;
  }

  to {
    stroke-dashoffset: 0;
  }
}
</style>
