<template>
  <span :style="locked ? `outline: 0px` : `outline: 2px solid #0288d1`">
    <span
      :class="`inputvalue`"
      ref="value"
      :contenteditable="!locked"
      @focus="focus"
      @blur="blur"
      @input="input"
    ></span>
    <span :class="locked ? '' : `placeholder`" :contenteditable="!locked" @focus="focus">{{ placeholder }}</span>
  </span>
</template>
<script>
export default {
  props: {
    // 填空内容
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      placeholder: this.value ? '' : '【请输入】',
    }
  },
  computed: {
    locked() {
      return this.$store.state.locked
    },
  },
  watch: {
    value() {
      this.$refs.value.innerHTML = this.value
      this.placeholder = this.value ? '' : '【请输入】'
    },
  },
  mounted() {
    this.$refs.value.innerHTML = this.value
  },
  methods: {
    // 点击占位空格时聚焦可编辑元素
    clickSeat() {
      this.$refs.value && this.$refs.value.focus()
    },
    // 聚焦时定位光标位置
    focus() {
      setTimeout(() => {
        this.setFocus(this.value.length)
      }, 0)
    },
    // 失焦时再赋值一遍
    blur(e) {
      if (this.value !== e.target.innerText) {
        this.$emit('input', e.target.innerText)
      }
    },
    // 输入时进行赋值
    input(e) {
      this.placeholder = e.target.innerText ? '' : '【请输入】'
    },
    // 聚焦后把光标放到最后
    setFocus(index) {
      let obj = this.$refs.value

      if (document.createRange) {
        // 获取选定对象
        let selection = getSelection()
        let range = document.createRange()

        range.setStart(obj.firstChild || obj, index)
        range.setEnd(obj.firstChild || obj, index)
        selection.removeAllRanges()
        selection.addRange(range)
      }
    },
  },
}
</script>
<style scoped>
.placeholder {
  color: #e65100;
}
.inputvalue {
  color: #000000;
}
</style>
