<template>
  <v-icon color="red" @click="change">mdi-help-circle</v-icon>
</template>
<script>
export default {
  props: {
    help: {
      type: String,
      default: '',
    },
  },
  methods: {
    change() {
      this.$store.commit('changeHelp', this.help)
      this.$store.commit('drawerRight', true)
    },
  },
}
</script>
