var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isAllowShowNode(_vm.nodeProps)),expression:"isAllowShowNode(nodeProps)"}],ref:"seeksRGNode",staticClass:"rel-node-peel",style:({
    left: _vm.nodeProps.x + 'px',
    top: _vm.nodeProps.y + 'px',
    opacity: _vm.nodeProps.opacity > 1 ? _vm.nodeProps.opacity / 100 : _vm.nodeProps.opacity,
  }),on:{"mousedown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"left",37,$event.key,["Left","ArrowLeft"]))return null;if('button' in $event && $event.button !== 0)return null;$event.stopPropagation();return _vm.onDragStart($event)},"mouseover":function($event){$event.stopPropagation();return _vm.onMouseHover($event)},"mouseout":function($event){$event.stopPropagation();return _vm.onMouseOut($event)},"click":function($event){$event.stopPropagation();return _vm.onclick($event)}}},[(
      (_vm.nodeProps.expandHolderPosition && _vm.nodeProps.expandHolderPosition !== 'hide') ||
      (_vm.graphSetting.defaultExpandHolderPosition &&
        _vm.graphSetting.defaultExpandHolderPosition !== 'hide' &&
        _vm.nodeProps.lot.childs &&
        _vm.nodeProps.lot.childs.length > 0)
    )?_c('div',{staticClass:"c-btn-open-close",class:['c-expand-positon-' + (_vm.nodeProps.expandHolderPosition || _vm.graphSetting.defaultExpandHolderPosition)]},[_c('span',{class:_vm.expandButtonClass,style:({ 'background-color': _vm.nodeProps.color || _vm.graphSetting.defaultNodeColor }),on:{"click":function($event){$event.stopPropagation();return _vm.expandOrCollapseNode.apply(null, arguments)}}})]):_vm._e(),(_vm.nodeProps.html)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.nodeProps.html)}}):_c('div',{staticClass:"rel-node",class:[
      'rel-node-shape-' + (_vm.nodeProps.nodeShape === undefined ? _vm.graphSetting.defaultNodeShape : _vm.nodeProps.nodeShape),
      'rel-node-type-' + _vm.nodeProps.type,
      // nodeProps.id === graphSetting.checkedNodeId ? 'rel-node-checked' : '',
      // nodeProps.selected ? 'rel-node-selected' : '',
      _vm.nodeProps.styleClass,
      _vm.hovering ? 'rel-node-hover' : '',
      _vm.nodeProps.innerHTML ? 'rel-diy-node' : '',
    ],style:({
      'background-color': _vm.nodeProps.color === undefined ? _vm.graphSetting.defaultNodeColor : _vm.nodeProps.color,
      color: _vm.nodeProps.fontColor === undefined ? _vm.graphSetting.defaultNodeFontColor : _vm.nodeProps.fontColor,
      border:
        (_vm.nodeProps.borderColor || _vm.graphSetting.defaultNodeBorderColor) +
        ' solid ' +
        (_vm.nodeProps.borderWidth || _vm.graphSetting.defaultNodeBorderWidth) +
        'px',
    })},[(!(_vm.graphSetting.hideNodeContentByZoom === true && _vm.graphSetting.canvasZoom < 40))?[_vm._t("node",null,{"node":_vm.nodeProps})]:_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }