export function blob2base64(blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(blob)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => {
      console.log('图片转换base64失败', error)
      reject(false)
    }
  })
}

/**
 * 二进制容器
 * @param {String} dataurl
 */
 export function getUint8Arr(dataurl) {
  // 截取base64的数据内容
  let arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      // 获取解码后的二进制数据的长度，用于后面创建二进制数据容器
      n = bstr.length,
      // 创建一个Uint8Array类型的数组以存放二进制数据
      u8arr = new Uint8Array(n)
  // 将二进制数据存入Uint8Array类型的数组中
  while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
  }
  return { u8arr, mime }
}