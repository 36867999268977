export const obj_field_level_1 = [{
		value: 1,
		text: '不完整的物场模型'
	},
	{
		value: 2,
		text: '有害的物场模型'
	},
	{
		value: 3,
		text: '不足的物场模型'
	},
	{
		value: 4,
		text: '测量或检测'
	},
	{
		value: 5,
		text: '标准解的应用'
	},
]

export const obj_field_level_2 = [{
		value: 1,
		parent: 1,
		text: '仅有一个物质',
		labels: ['S1']
	},
	{
		value: 2,
		parent: 1,
		text: '有两个物质，但缺少场',
		labels: ['S1', 'S2']
	},
	{
		value: 3,
		parent: 1,
		text: '有两个物质，两个物质有场，但没有期望的作用发生',
		labels: ['S1', 'S2']
	},
	{
		value: 4,
		parent: 2,
		text: '物场模型虽然能够工作，但与我们期望相反',
		labels: ['F', 'S1', 'S2']
	},
	{
		value: 5,
		parent: 3,
		text: '物产模型虽然是有用的，但实际效果没有达到要求',
		labels: ['F', 'S1', 'S2']
	},
	{
		value: 6,
		parent: 4,
		text: '这一类标准解专门用于检测和测量问题'
	},
	{
		value: 7,
		parent: 5,
		text: '这一类标准解是关于标准解应用的，是不能向工程系统中添加物质时应该采取的方案'
	},
]

export const obj_field_level_3 = [{
		main: 1,
		parent: 1,
		text: '第1类——建立和拆解物场模型',
		labels: ['S1']
	},
	{
		main: 1,
		parent: 2,
		text: '第1类——建立和拆解物场模型',
		labels: ['S1']
	},
	{
		main: 1,
		parent: 3,
		text: '第1类——建立和拆解物场模型',
		labels: ['S1']
	},
	{
		main: 1,
		parent: 4,
		text: '第1类——建立和拆解物场模型',
		labels: ['S1']
	},
	{
		main: 2,
		parent: 5,
		text: '第2类——增强物场模型',
		labels: ['S1', 'S2']
	},
	{
		main: 3,
		parent: 5,
		text: '第3类——系统向超系统或微观级转化',
		labels: ['S1', 'S2']
	},
	{
		main: 4,
		parent: 6,
		text: '第4类',
		labels: ['S1', 'S2']
	},
	{
		main: 5,
		parent: 7,
		text: '第5类',
		labels: ['F1', 'S1', 'S2']
	},
]

export const obj_field_level_4 = [{
		main: 1,
		sub: 1,
		text: '第1.1类——建立完整的物场模型'
	},
	{
		main: 1,
		sub: 2,
		text: '第1.2类——拆解物场模型'
	},
	{
		main: 2,
		sub: 1,
		text: '第2.1类——向复杂的物场模型转化'
	},
	{
		main: 2,
		sub: 2,
		text: '第2.2类——增强物场模型'
	},
	{
		main: 3,
		sub: 1,
		text: '第3.1类——转化为双系统或多系统'
	},
	{
		main: 3,
		sub: 2,
		text: '第3.2类—系统转化'
	},
	{
		main: 4,
		sub: 1,
		text: '第4.1类——间接方法'
	},
	{
		main: 4,
		sub: 2,
		text: '第4.2类——建立新的测量系统'
	},
	{
		main: 4,
		sub: 3,
		text: '第4.3类——增强测量系统'
	},
	{
		main: 4,
		sub: 4,
		text: '第4.4类——转化为铁-场模型'
	},
	{
		main: 4,
		sub: 5,
		text: '第4.5类——测量系统进化的趋势'
	},
	{
		main: 5,
		sub: 1,
		text: '第5.1类——引入物质'
	},
	{
		main: 5,
		sub: 2,
		text: '第5.2类——引入场'
	},
	{
		main: 5,
		sub: 3,
		text: '第5.3类——相变'
	},
	{
		main: 5,
		sub: 4,
		text: '第5.4类—应用物理效应和现象的特性'
	},
	{
		main: 5,
		sub: 5,
		text: '第5.5类——产生物质的高级和低级方法'
	},
]

export const obj_field_level_5 = [{
		main: 1,
		sub: 1,
		tail: 1,
		text: '将不完整的物场模型补充完整',
		has: true,
		labels: ['F', 'S1', 'S2'],
		tooltip: '如果有一个物体（目标）不容易按要求改变，并且条件不包含引入物质和场的任何限制，则通过合成物质－场系统来解决该问题：该物体受到物场的作用，这个物场会使物体发生必要的变化。 ',
		case: '为了从粉末状物质（S2）中除去空气（S1），使粉末受到离心力（F）。 '
	},
	{
		main: 1,
		sub: 1,
		tail: 2,
		text: '建立内部复合物场模型',
		has: true,
		labels: ['F', 'S1', 'S2', 'S3'],
		tooltip: '如果存在按要求不易改变的物质－场系统，且条件不包含对向给定物质引入添加剂的任何限制，则通过向内部复杂的物质－场系统过渡（永久或临时），向现有物质中引入添加剂来解决该问题。这些添加剂增强了物质－场系统可控性或赋予其所需的性能。 ',
		case: '为了通过眼睛（S1）检测非常小的液滴（S2），预先向液滴中添加发光物质（S3）。然后，使用紫外光（F），易于检测液滴。 '
	},
	{
		main: 1,
		sub: 1,
		tail: 3,
		text: '建立外部复合物场模型',
		has: true,
		labels: ['F', 'S1', 'S2', 'S3'],
		tooltip: '如果存在按要求不易改变的物质-场系统，且条件包含在现有物质中引入添加剂的限制，则通过过渡（永久或临时）到外部复杂物质-场系统来解决该问题，在这些物质之一上附加一种外部物质，该外部物质可提高物质-场系统可控性或使其具有所需的性能。 ',
		case: '为了通过眼睛（S1）检测管道中气体（S2）的泄漏，管道的外表面覆盖有与气体反应并产生可见气泡的物质（S3）（图中的第一种情况）。 '
	},
	{
		main: 1,
		sub: 1,
		tail: 4,
		text: '引入环境的物场模型',
		has: true,
		labels: ['F', 'S1', 'S2', 'S3'],
		tooltip: '如果存在不容易根据需要改变的物质-场系统，并且条件包含对引入或附着物质的限制，则通过将外部环境作为必须引入的新物质来合成物质-场系统来解决该问题 ',
		case: '一种不平衡振动装置，其中不平衡物质（S1）具有翼的形状以增加扰动力。在此解决方案中，空气充当了新物质（Se） '
	},
	{
		main: 1,
		sub: 1,
		tail: 5,
		text: '引入环境和添加物的物场模型',
		has: true,
		labels: ['F', 'S1', 'S2', 'S3'],
		tooltip: '如果外部环境不包含合成物质-场系统所需的现成物质，则可以通过用另一个外部环境替换、或通过分解环境、或通过向环境中引入添加剂来获得这些物质 ',
		case: '为了提高滑动摩擦系数，对液体润滑剂（S2）充入气体（Sed）达到润滑效果 '
	},
	{
		main: 1,
		sub: 1,
		tail: 6,
		text: '最小作用效果',
		has: true,
		labels: ['F', 'Fmax', 'S1', 'S2'],
		tooltip: '如果需要最小（测量的、最佳的）作用效果，但在问题的条件下很难或不可能提供这种作用效果，则使用最大作用（场或物质），同时去除多余的作用。“物质过量”由“场”移除，而“场过量”由“物质”移除 ',
		case: '为了精确地喷涂零件，首先将零件（S1）装入带有涂料的容器中（S2max），然后旋转，由于离心力（F），将多余的涂料去除。 '
	},
	{
		main: 1,
		sub: 1,
		tail: 7,
		text: '最大作用效果',
		has: true,
		labels: ['F', 'S1', 'S2'],
		tooltip: '如果要求对一种物质产生最大作用，但这是不允许的，则必须保留最大作用，但应将其指向与第一种物质相连的另一种物质 ',
		case: '制造钢筋混凝土时，可以用金属丝代替钢筋。但是金属丝必须被拉长。为此，必须将金属丝加热到 70°C，这是不允许的。金属丝（S1）连接到钢筋（S2）上，在金属丝保持冷态时，该钢筋被加热（热场 Fmax）。 '
	},
	{
		main: 1,
		sub: 1,
		tail: 8,
		text: '引入保护性物质'
	},
	{
		main: 1,
		sub: 2,
		tail: 1,
		text: '引入外部物质消除有害效应',
		has: true,
		labels: ['F', 'S1', 'S2', 'S3'],
		tooltip: '如果在物质-场系统中的两种物质之间出现有用和有害的影响，并且不需要保持物质之间的直接接触，则通过在它们之间引入第三种物质来解决该问题。第三种物质也可以通过暴露于现有领域而从本发明的物质中获得。特别地，要引入的物质可以是气泡、泡沫等。 ',
		case: '第三种物质也可以通过暴露于现有领域而从本发明的物质中获得。特别地，要引入的物质可以是气泡、泡沫等。 '
	},
	{
		main: 1,
		sub: 2,
		tail: 2,
		text: '通过改进现有物质来消除有害效应',
		has: true,
		labels: ['F', 'S1', 'S2', 'S1modified', 'S2modified'],
		tooltip: '如果在物质-场系统中的两种物质之间存在有益和有害的影响，并且不需要保持两种物质之间的直接接触，并且禁止或不方便使用外来物质，则通过在两种物质之间引入第三种物质来解决该问题。在这种情况下，第三物质是第一种或第二种物质的变体。第三种物质可以通过接触现有的场而从现有物质中获得。特别地，要引入的物质可以是气泡、泡沫等。此外，物质的改变可能导致其运动规律的改变：移动／ 固定组成部分等。 ',
		case: '在流体动力学中，箔的表面可能会被由箔（S2）和水（S1）之间的摩擦产生的气蚀损坏。冷冻箔片表面，周围的水将冻结，并在箔片表面形成冰层。（S1modified）。 '
	},
	{
		main: 1,
		sub: 2,
		tail: 3,
		text: '引入一个物质来吸收有害作用（引入牺牲品）',
		has: true,
		labels: ['F', 'S1', 'S2'],
		tooltip: '如果需要消除场对物质的有害影响，则可以通过引入第二种物质来解决该问题，该第二种物质自身吸收场的有害影响。 ',
		case: '为了保护带有水的管道（S1），当水结冰并产生额外压力（F）时可能发生破裂，在管道的内表面涂上橡胶涂层（S2） '
	},
	{
		main: 1,
		sub: 2,
		tail: 4,
		text: '引入一个新的场F2来抵消有害作用',
		has: true,
		labels: ['F', 'F2', 'S1', 'S2'],
		tooltip: '如果在一个物质场系统中，两种物质之间出现有益和有害的影响，并且必须保持物质之间的直接接触，则可以通过过渡到双物质-场系统来解决这个问题——有用的影响由现有的场提供，而新的场抵消了有害的影响（或者将有害影响转化为有用的影响）。 ',
		case: '为了帮助花的授粉（S2），使用气流（S1）。然而，空气也会使花关闭。建议用静电放电（F2）打开花。 '
	},
	{
		main: 1,
		sub: 2,
		tail: 5,
		text: '消除磁引起的有害影响',
		has: true,
		labels: ['Fmagnetic', 'Fthermal', 'S1', 'S2'],
		tooltip: '如果有必要用磁场分解物质-场系统，则通过使用能够“关闭”物质铁磁性质的物理效应（例如，在达到或高于居里点的加热过程中通过去磁）来解决该问题。：如果使用补偿彼此磁场影响的磁体系统，则磁场可能在适当的时刻出现。当其中一个磁体被消磁时，在系统中产生磁场。 ',
		case: '在焊接过程中，难以在焊接区域（S1）中插入铁磁性粉末。焊接电流的电磁场使颗粒（S2）远离焊接区域。可将粉末加热到居里点以上，使其消磁。'
	},
	{
		main: 2,
		sub: 1,
		tail: 1,
		text: '链式物场模型',
		has: true,
		labels: ['F', 'F2', 'S1', 'S2', 'S3', 'S4'],
		tooltip: '通过将物场系统的其中一个部分转换为独立可控的物场系统，从而形成链式物场系统，可以提高物场系统的效率 ',
		case: '由于机械力（F），可将金属物体（S1）与楔（S2）可靠地固定。但是如何去除楔子呢？通过由两个部件（S3和 S4）组成的楔块来设计新的楔块，其中一个部件（S4）由具有低熔化温度的合金制成。'
	},
	{
		main: 2,
		sub: 1,
		tail: 2,
		text: '双物场模型',
		has: true,
		labels: ['F', 'F2', 'S1', 'S2'],
		tooltip: '如果需要提高物场系统的效率，并且不允许替换物场系统的元件，则可以通过引入第二个易于控制的场来合成双物场系统来解决该问题。 ',
		case: '通过使用电磁场（F2）额外地旋转液态金属来改进经由钢包（S2）中的熔融金属（S1）的高度，以改善对液体压力（F）的控制。'
	},
	{
		main: 2,
		sub: 2,
		tail: 1,
		text: '使用更可控制的场',
		has: true,
		labels: ['F', 'Fmodifien', 'S1', 'S2'],
		tooltip: '物质-场系统的效率可以通过用控制良好的场代替不受控制的（或控制不好的）场（例如，通过用机械场代替引力场，用电场代替机械场等等）来提高。在某些情况下，不仅可以通过用另一个场替换给定的场，还可以通过沿着以下路径修改当前场来提高场的可控性：永久的场→ 单调变化的场→ 脉冲的场→ 可变的场→ 变频变幅的场→ 等。 ',
		case: '可以使用水射流代替非均匀金属切削的金属刀片。此外，可以使用激光束代替水射流。'
	},
	{
		main: 2,
		sub: 2,
		tail: 2,
		text: '提高物质组成部分的细分程度',
		has: true,
		labels: ['F', 'Fmodifien', 'S1', 'S2'],
		tooltip: '"可以通过增加作为物场系统中的“工具”的对象的细分程度来提高物场系统的效率，在其进化的最后，该对象将被一个能够提供工具功能的新的场所取代。该标准显示了技术发展的一个主要趋势，即对象（“工具”）或其与产品相互作用并产生产品所需修改的部分的碎片化。该工具的发展经历了以下阶段：1. 固体物体2. 碎片化物体3. 粉末4. 液体5. 气体6. 一个新的场"',
		case: '"切削工具的演变： 1. 平刃2. 一种带齿刀片3. 具有研磨涂层的刀片4. 水射流（高压水切割）5. 等离子切割6. 激光切割"'
	},
	{
		main: 2,
		sub: 2,
		tail: 3,
		text: '使用毛细管和多孔的物质',
		has: true,
		labels: ['F', 'S1', 'S2'],
		tooltip: '"用毛细多孔物体代替物场模式下的固体物体，可以提高物场系统的效率。毛细多孔物体的进一步演进经历了以下阶段：■ 固体物体■ 具有一个空腔的物体■ 具有多个空腔的物体（穿孔）■ 毛细多孔物体■ 具有预定多孔结构的毛细多孔物体注：过渡到毛细多孔物体使液体物质能够被放置在孔隙中并使用物理效应。"',
		case: '"一束毛细管（S2-CP）比单个大尺寸管（S2）更精确地将液体胶水（S1）涂在要粘合的表面上"'
	},
	{
		main: 2,
		sub: 2,
		tail: 4,
		text: '提高系统动态性',
		has: true,
		labels: ['F', 'S1', 'S2'],
		tooltip: '通过增加物场系统的动态性，即通过变化系统结构变化过渡到更灵活、更快速，可以提高物场系统的效率。使一个物质成为动态的，首先将其分成两个连接的部分，然后沿着以下路线继续：一个接头→多个接头→柔性物体 通过从永久场（或与物质一起的场）过渡到脉冲场，可以使场变得更动态。 ',
		case: '"1. 由两个铰链组成的门2. 由多个铰接组成的门（“风琴”门）3. 一种柔性门"'
	},
	{
		main: 2,
		sub: 2,
		tail: 5,
		text: '更改场的结构',
		has: true,
		labels: ['F', 'S1', 'S2'],
		tooltip: '物质-场系统的效率提高，可以通过从一个或多个具有无序结构的均匀场过渡到具有确定的时空结构（永久或可变）的非均匀场。如果要将某个空间结构赋予物质对象，则该过程可以在结构与实体对象所需结构相匹配的场中进行。 ',
		case: '混合两种磁粉时，在第二种磁粉中加入一层第一种磁粉，并施加不均匀磁场。'
	},
	{
		main: 2,
		sub: 2,
		tail: 6,
		text: '改变物质对象的结构',
		has: true,
		labels: ['F', 'S1', 'S2'],
		tooltip: '物质-场系统的效率提高，可以通过从均匀或无序结构的物质过渡到非均匀的或具有预定时空结构（永久的或可变的）的物质。特别是，如果在系统的某些位置（点，线）需要磁场的增强作用，那么会在这些位置预先引入产生所需磁场的物质。 ',
		case: '为了制造具有确定朝向的空间结构的多孔材料，预先将线插入到软材料中。当材料变成固体后，这些线也耗尽了'
	},
	{
		main: 2,
		sub: 3,
		tail: 1,
		text: '使F和S1或S2的自然频率匹配或不匹配',
		tooltip: '通过将作用场的频率与产品(或工具)的固有频率匹配(或不匹配)，可以提高物质场系统的效率。 ',
		case: '"1. 按摩的节奏与病人的脉搏同步2. 在电弧焊接中，使磁场的频率等于熔化电极的固有频率"'
	},
	{
		main: 2,
		sub: 3,
		tail: 2,
		text: '匹配F1和F2的频率',
		tooltip: '可以通过匹配（或不匹配）所用场的频率来提高复杂物质-场系统的效率。 ',
		case: '要在零件上涂覆一种物质，该物质以粉末形式施加。为了提供均匀有规则的分布，电流脉冲和用于制造均匀涂层的磁场脉冲的频率相等。'
	},
	{
		main: 2,
		sub: 3,
		tail: 3,
		text: '执行两个不兼容的操作',
		tooltip: '"如果必须由同一系统执行两个不兼容的操作（例如，更换和测量），则应在一个操作之间的暂停期间执行另一个操作。一般来说，一个动作中的停顿应该用另一个有用的动作来填充"',
		case: '为了提供精确的接触焊接，在电流脉冲之间的间歇期间进行测量。'
	},
	{
		main: 2,
		sub: 4,
		tail: 1,
		text: '引入铁磁性物质和磁场',
		has: true,
		labels: ['F', 'S1', 'S2'],
		tooltip: '"如果必须由同一系统执行两个不兼容的操作（例如，更换和测量），则应在一个操作之间的暂停期间执行另一个操作。一般来说，一个动作中的停顿应该用另一个有用的动作来填充"',
		case: '为了提供精确的接触焊接，在电流脉冲之间的间歇期间进行测量。'
	},
	{
		main: 2,
		sub: 4,
		tail: 2,
		text: '引入铁磁微粒和磁场',
		has: true,
		labels: ['F', 'Fmagnetic', 'S1', 'S2'],
		tooltip: '通过使用铁磁粒子（或添加铁磁粒子）代替物-场系统中的一种物质——碎片、颗粒、细粒等，并使用磁场或电磁场来控制粒子，可以提高对物质-场系统的控制效率。控制效率随着铁磁粒子和引入铁磁粒子的物质的细分程度的提高而提高：铁粒子：颗粒→ 粉末→ 细分散颗粒→ 磁性液体。物质：固体→ 颗粒→ 粉末→ 液体。',
		case: '为了提高用于提升易碎的不规则形状的零件（S1）的机器人抓手（S2）的效率，将抓手制成填充有铁磁性粉末（SF）的柔性容器。在环绕放置在必须被提升的零件周围之后，电磁场（F 磁）被施加到粉末上。因此，粉末颗粒相互挤压，并提供抓手所需的刚度。'
	},
	{
		main: 2,
		sub: 4,
		tail: 3,
		text: '运用磁流体',
		has: true,
		labels: ['F', 'Fmagnetic', 'S1', 'S2'],
		tooltip: '铁磁物质-场系统的效率可以通过使用磁性流体来提高：悬浮在煤油、硅胶或水中的非常小的胶状铁磁颗粒。悬浮液的磁化时间为毫秒，因此磁场可以用来将液体转化为假固态。如果磁场移动，磁性流体就会随之移动。',
		case: '1. 基于磁性流体的减震器可以在几毫秒内提供所需的力2. 基于磁性液体的密'
	},
	{
		main: 2,
		sub: 4,
		tail: 4,
		text: '在铁磁场模型中应用毛细多孔结构',
		has: true,
		labels: ['F', 'Fmagnetic', 'S1', 'S2'],
		tooltip: '铁磁物质-场系统的效率可以通过使用许多 F 物质-场系统中固有的毛细多孔结构来提高。',
		case: '一种波动焊接机，包括涂覆有铁磁粒子层的磁性圆筒。其主要目的是去除多余的焊料。同时，圆筒的多孔设计允许助焊剂通过孔道供给到焊接位置。'
	},
	{
		main: 2,
		sub: 4,
		tail: 5,
		text: '内部或外部复合的铁磁场模型',
		has: true,
		labels: ['F', 'S1', 'S2'],
		tooltip: '如果需要提高控制效率，并且含有铁磁颗粒的物质不能被替换，则必须通过在其中一种物质中引入添加剂来构成内部和外部复杂的铁磁物质-场系统',
		case: '为了用电磁铁（S2）运输非磁性物体（S1），将物体放置在铁磁性粉末中。'
	},
	{
		main: 2,
		sub: 4,
		tail: 6,
		text: '向环境中引入铁磁粒子',
		has: true,
		labels: ['Fmagnetic', 'S1', 'S2'],
		tooltip: '如果需要提高控制效率，并且含有铁磁粒子的物质不能被替换，则应在外部环境中引入铁磁粒子。然后，利用磁场，改变环境参数，使系统变得更加可控。',
		case: '为了控制物体（S1）在液体（S2）中的下降，将铁磁粒子（SF）加入到液体中，从而形成磁性流体。磁性流体的密度将随外加电磁场的强度成比例地改变。'
	},
	{
		main: 2,
		sub: 4,
		tail: 7,
		text: '应用物理效应和现象的铁磁场',
		tooltip: '铁磁系统的可控性可以通过使用物理效应来改善。',
		case: '为了降低磁放大器中的磁噪声水平，对磁放大器的磁芯进行加热。加热温度保持在制造磁芯材料居里温度的大约 92-99%。'
	},
	{
		main: 2,
		sub: 4,
		tail: 8,
		text: '增加铁磁场的动态化',
		has: true,
		labels: ['F', 'S1', 'S2'],
		tooltip: '"通过提高系统动态性，例如，通过过渡到系统结构更灵活、更快速变化，可以提高 F 物-场系统的效率。使物质更具动态性，最开始是将其分为两个连接的部分，然后沿着以下路线继续：一个接头→ 多个接头→ 柔性物质.场是通过从永久性效应（或场与物质一起的永久性效应）过渡到脉冲效应，使场成为动态的"',
		case: '"一种用于测量空心非磁性物体薄壁厚度的装置，包括：放置在物体内部的铁磁元件和位于物体外部的感应转换器。为了提高测量精度，将铁磁元件制成一个涂有铁磁粉末的充气外壳"'
	},
	{
		main: 2,
		sub: 4,
		tail: 9,
		text: '改变场的结构',
		has: true,
		labels: ['F', 'Fstructured', 'S1', 'S2'],
		tooltip: 'F 物质-场系统的效率可以通过从均匀场或具有确定时空结构（永久的或可变的）的场的转换来提高。如果将某种空间结构赋予物质对象，则该过程可以在具有与物质对象的所需结构相匹配的结构的场中进行。',
		case: '一种在热塑性材料表面上制造纤维的方法，在必须形成纤维的地方将铁磁粒子（SF）施加到材料（S1 结构）上，然后加热该材料直到其表面层熔化。在粒子上方放置一个电磁铁，吸引它们，进而“拉出”塑料纤维。当新形成的纤维变为固体时，电磁铁将粒子从材料中去除。'
	},
	{
		main: 3,
		sub: 1,
		tail: 1,
		text: '形成双系统或多系统',
		tooltip: '在系统演化的任何阶段，系统效率都可以通过将系统与另一个系统（或多个系统）结合起来形成一个双或多系统来提高。注：对于简单地形成的双系统和多系统，将两个或多个组件组合到一起。要组合的组件可以是物质、场、物质－场对和整个物质－场系统。 ',
		case: '为了加工一碟薄玻璃板，需要将几个板放在一起以防止玻璃破裂。'
	},
	{
		main: 3,
		sub: 1,
		tail: 2,
		text: '在双系统和多系统中开发连接',
		tooltip: '通过开发系统元素之间的连接，可以提高双系统和多系统的效率。注：双系统和多系统的元素之间的连接可以更刚性或更动态 ',
		case: '为了使三台起重机提升非常重的零件的过程同步，使用一个刚性三角形来同步起重机的移动部件。'
	},
	{
		main: 3,
		sub: 1,
		tail: 3,
		text: '增加系统组件之间的差异',
		tooltip: '"可以通过增加系统组件之间的差异来提高双系统和多系统的效率。建议采用以下进化路线：相似组件（相同颜色的铅笔）→ 有偏重的组件（不同颜色的铅笔）→ 有差异的组件 （成套绘图工具）→ “组件＋ 相反功能的组件”的组合（带橡皮的铅笔）"',
		case: '为了焊接两个厚金属板，使用电极多次焊接。电极按顺序排列，每个后续电极的焊接电流比前一个大，并且穿透深度比上一个深度大。'
	},
	{
		main: 3,
		sub: 1,
		tail: 4,
		text: '将多个组件合并到单个组件中',
		tooltip: '通过减少辅助组件，可以通过“卷曲”（将多个组件合并到单个组件中）来提高双系统和多系统的效率。完全卷曲的双系统和多系统再次成为单系统，合并可以在系统的另一个层次上重复。 ',
		case: '可以使用具有不同颜色指示箭头的单个指示器，代替仪表板上的三个独立的指示器'
	},
	{
		main: 3,
		sub: 1,
		tail: 5,
		text: '在系统及其组件之间分配不兼容的属性',
		tooltip: '通过在系统及其组件之间分配不相容特性，可以提高双系统和多系统的效率。这是通过使用两个级别来实现的，系统所在的结构，其中系统作为整体具有某种性质“A”，而其组成部分（颗粒）具有“反向 A”的性质 ',
		case: '虎钳的工作部件由能够彼此相对移动的分段块制成。各种形状的零件可以被快速地夹紧。'
	},
	{
		main: 3,
		sub: 2,
		tail: 1,
		text: '向微观层面过渡',
		tooltip: '系统在其进化的任何阶段的效率都可以通过从宏观层面向微观层面的转变来提高：系统或其组成部分被一种能够在与场相互作用时传递所需功能的物质所取代。注：一个物质有许多微观层次的状态（晶格、分子、离子、畴、原子、基本粒子、场等）。因此，在解决一个问题时，应该考虑到从一个微观层次过渡到另一个较低层次的各种选择 ',
		case: '可以通过将显微镜台固定在受到热场作用的金属棒上，而不是用微型螺钉来定位。由于热膨胀，杆会随温度变化而膨胀和收缩。'
	},
	{
		main: 4,
		sub: 1,
		tail: 1,
		text: '改变系统使检测或测量不再需要',
		tooltip: '如果问题涉及检测或测量，则应以这样的方式改变问题，以便根本不需要执行检测或测量 ',
		case: '为了防止持续工作的电动机过热，它的磁极是由一种合金制成的，其居里点等于电动机的最高允许温度。当温度达到居里点时，磁极的磁性消失，电机停止运转。'
	},
	{
		main: 4,
		sub: 1,
		tail: 2,
		text: '测量复制品',
		tooltip: '如果问题涉及检测或测量，并且不可能通过改变问题来消除对检测或测量的需要，则更改／ 检测对象（例如图片）的复制品的属性 ',
		case: '测量蛇的长度可能是危险的。在摄影图像上测量其长度，然后重新计算，获得成功。'
	},
	{
		main: 4,
		sub: 1,
		tail: 3,
		text: '用两个连续测量代替一个测量',
		tooltip: '如果一个问题涉及到检测或测量，并且问题不能被改变以消除测量的需要-并且不可能使用复制品或图片-则将此问题转化为连续检测变化的问题注：任何测量都是有一定的精确度的。因此，即使问题涉及连续测量，也总是可以挑出一个涉及两个连续检测的简单测量行为。这样问题变得简单得多。 ',
		case: '为了测量温度，可以使用根据温度改变颜色的材料。或者，可以使用几种材料来表示不同的值。'
	},
	{
		main: 4,
		sub: 2,
		tail: 1,
		text: '建立测量物场模型',
		has: true,
		labels: ['F1', 'F2', 'S1'],
		tooltip: '如果一个非物质-场系统不容易被探测或测量，那么可以通过合成一个简单或双物质-场系统来解决这个问题不是直接测量或检测物质的所需参数“A”，而是测量或检测另一参数“B”， 该参数与参数“A”相关，但属于第二种物质。要引入的场的变化应该能够指示我们需要检测或测量的参数“A”的状态。 ',
		case: '为了查明液体（S1）开始沸腾的时刻，电流（Fnew）通过液体。在沸腾过程中，会形成气泡（S2），它们会显著增加液体的电阻（F new-modified）。'
	},
	{
		main: 4,
		sub: 2,
		tail: 2,
		text: '在系统中引入易检测的添加剂',
		has: true,
		labels: ['F1', 'F2', 'S1', 'S2'],
		tooltip: '如果一个系统（或其部分）不提供检测或测量功能，则可通过过渡到内部或外部复杂的测量物质-场系统，引入易于检测的添加剂来解决问题。 ',
		case: '为了通过眼睛（S1）检测冰箱内的冷却剂（S2）的泄漏，将冷却剂与发光粉（S3）混合。然后将冰箱暴露于紫外光（Fnew）下。'
	},
	{
		main: 4,
		sub: 2,
		tail: 3,
		text: '向环境中引入易检测的添加剂',
		has: true,
		labels: ['F1', 'F2', 'S1', 'S2'],
		tooltip: '如果一个系统在给定的时间内难以检测或测量，并且不允许或不可能向物体中引入添加剂，则应在外部环境中引入能够产生易于检测和可测量场的添加剂。更改环境的状态将指示对象的状态。 ',
		case: '为了检测与另一个圆盘接触的旋转金属盘上的磨损，在系统中已经存在的润滑油（S2）中引入发光粉（SE）。汇集在机油中的金属颗粒会减少机油的发光。'
	},
	{
		main: 4,
		sub: 2,
		tail: 4,
		text: '从环境中获得易检测的添加剂',
		tooltip: '如果不可能在外部环境中引入易检测的添加剂，则可以在环境本身中获得这些添加剂，例如，通过分解环境或改变环境的聚集状态注：通常可以通过分解外部环境而获得的添加剂，特别是电解、气蚀或任何其他方法产生的气体或蒸汽泡通常可以通过分解外部环境而获得的添加剂 ',
		case: '例子：管道中水流的速度可以通过由空化产生的气泡的量来测量。'
	},
	{
		main: 4,
		sub: 3,
		tail: 1,
		text: '利用物理效应和现象',
		tooltip: '利用物理效应可以提高测量物场模型的效率。 ',
		case: '"可以通过测量收缩系数的变化来测量液体的温度，所述收缩系数的变化取决于温度。"'
	},
	{
		main: 4,
		sub: 3,
		tail: 2,
		text: '激发谐振',
		tooltip: '如果无法直接检测或测量系统中的变化，并且没有磁场可以通过系统，则可以通过激发（整个系统或其部分的）谐振来解决问题，频率变化指示正在发生的变化。 ',
		case: '为了测量容器中物质的质量，容器受到机械压力产生谐振。振荡的频率取决于系统的质量。'
	},
	{
		main: 4,
		sub: 3,
		tail: 3,
		text: '检测固有频率的变化',
		tooltip: '如果谐振在系统中不可能被激发，其状态可以由与系统相连的对象（外部环境）的固有频率的变化来确定 ',
		case: '沸腾液体的质量可以通过测量其蒸发产生的气体的固有频率来测量。'
	},
	{
		main: 4,
		sub: 4,
		tail: 1,
		text: '使用铁磁物质和磁场',
		tooltip: '可以通过使用铁磁物质和磁场来提高物-场系统的测量效率。注：本标准表明了不需分割铁磁性物质的使用方法。 ',
		case: '为了便于在包装密封后检测包装内的某些位置，事先在这些位置加上磁性标记。'
	},
	{
		main: 4,
		sub: 4,
		tail: 2,
		text: ' 用铁磁性粒子代替物质',
		has: true,
		labels: ['F1', 'F2', 'S1', 'S2'],
		tooltip: '通过过渡到铁磁物质场系统，用铁磁性粒子（或添加铁磁性粒子）代替其中一种物质，以及通过检测或测量磁场来提高检测或测量的效率 ',
		case: '为了测量聚合物复合材料（S2）的凝固程度，预先在聚合物中加入铁磁性粉末（SF）。凝固过程会改变复合材料的磁导率，可以通过磁场（Fnew）来测量'
	},
	{
		main: 4,
		sub: 4,
		tail: 3,
		text: '在物质中引入铁磁性添加剂',
		has: true,
		labels: ['F1', 'F2', 'S1', 'S2'],
		tooltip: '如果需要通过过渡到铁磁物质-场系统来提高检测或测量的效率，并且不允许用铁磁粒子代替该物质，则通过在物质中引入（或附着）铁磁添加剂，合成一个复杂的铁磁物-场系统，从而实现向 F 物场系统的转变。本标准与标准 4.4.2 类似，但在不允许用铁磁粒子代替物质时使用。 ',
		case: '用液压冲击破碎岩石时，使用了液体。为了检测液体的状态，在液体中加入铁磁性粉末，并进行磁性测量'
	},
	{
		main: 4,
		sub: 4,
		tail: 4,
		text: '在环境中引入铁磁性添加剂',
		tooltip: '如果为了提高检测或测量的效率，需要过渡到 F 物质场系统，并且不允许引入铁磁性粒子，则将铁磁性粒子引入外部环境。 ',
		case: '为了研究液体中运动的小型容器形成的波，在液体中加入铁磁性粒子。'
	},
	{
		main: 4,
		sub: 4,
		tail: 5,
		text: '运用物理效应和现象',
		tooltip: '利用物理效应，如居里点、霍普金斯和巴克豪森效应、磁弹性效应等，可以提高测量的F 物质-场系统的效率。 ',
		case: '为了防止持续工作的电动机过热，它的磁极是由一种合金制成的，其居里点等于电动机的最大允许温度。当温度达到居里点时，磁极的磁性消失，电机停止。'
	},
	{
		main: 4,
		sub: 5,
		tail: 1,
		text: '向双系统或多系统转化',
		tooltip: '测量系统在其发展的任何阶段的效率都可以通过形成双系统或多系统来提高。注：要形成双组分和多组分体系，需要将两个或多个组件组合在一起。要组合的组件可以是物质、场、物质-场对或物质-场系统 ',
		case: '精确测量小甲虫的温度是很困难的。然而，如果有许多甲虫放在一起，温度可以很容易地测量'
	},
	{
		main: 4,
		sub: 5,
		tail: 2,
		text: '测量待测物产生的衍生物（测量派生）',
		tooltip: '测量系统的发展是向着派生功能可控的方向发展，沿着以下进化路径过渡：一个功能的测量→ 功能一次派生的测量→ 功能二次派生的测量。 ',
		case: '岩石中的应力变化定义为岩石中电阻变化的速度。'
	},
	{
		main: 5,
		sub: 1,
		tail: 1,
		text: '在限制条件下将物质引入系统',
		tooltip: '如果有必要在系统中引入一种物质，但这是不允许的，则可以使用“空”代替该物质。注：“空”通常是气体物质，如空气或在固体物体中形成的空腔。在某些情况下，“空”可由其它物质形成，例如液体（泡沫）或松散的物体。 ',
		case: ''
	},
	{
		main: 5,
		sub: 1,
		tail: 2,
		text: '将产品用作工具',
		tooltip: '如果系统不容易按照要求进行更改，并且条件不允许更改作为工具的组件或引入添加剂，则必须使用产品而不是“工具”，将产品划分为相互作用的组件 ',
		case: ''
	},
	{
		main: 5,
		sub: 1,
		tail: 3,
		text: '物质的“自消失”',
		tooltip: '当系统中引入的物质完成了它的功能后，它要么消失，要么变得与系统中或外部环境中的物质无法区分注：引入的物质可能由于化学反应或相变而消失 ',
		case: '一种用填充剂清洗通过液体流动的中空物体的新方法。为了在清洗后从物体上可靠地除去填充剂，使填充剂成为容易蒸发的物质。'
	},
	{
		main: 5,
		sub: 1,
		tail: 4,
		text: '运用膨胀结构和泡沫',
		tooltip: '如果有必要引入大量物质，但这是不允许的，则应使用可充气结构或泡沫形式的“空隙”作为物质。注：引入泡沫或充气结构解决了“物质多/物质少”的矛盾。 ',
		case: '如果需要运输一架不工作的飞机，可将充气袋放在飞机机翼下。反过来，这些袋子被放置在汽车上。当袋子充气后，它们把飞机抬起来，现在可以很容易地运输。'
	},
	{
		main: 5,
		sub: 2,
		tail: 1,
		text: '使用现有场，而不是引入新场',
		tooltip: '如果必须在物-场系统中引入场，则应首先使用系统或其组成部分中包含的所有现有的场。注：使用系统中已经存在的物质和场可以提高系统的理想度：系统执行的功能数量增加，而不增加所使用组件的数量 ',
		case: '一种在液氧中分离气体和液体的方法可以通过旋转管道中的流体来实现。离心力会将液体压在管壁上，而气体则会流过管壁。'
	},
	{
		main: 5,
		sub: 2,
		tail: 2,
		text: '使用外部环境的场',
		tooltip: '如果必须在物-场系统中引入一个场，并且不可能使用系统中已经存在的场，则应使用来自外部环境的场。注：使用外部环境场（重力场、热场、压力场等）可提高系统的理想度：系统执行的功能数量在不增加所用组件数量的情况下增加',
		case: '使用放置在河流水流中的喷射器，通过连接在两者之间的管道吸走桥梁表面多余的水。'
	},
	{
		main: 5,
		sub: 2,
		tail: 3,
		text: '使用现有物质作为场的来源',
		tooltip: '如果必须在物质-场系统中引入一个场，但不可能使用已经存在于系统或外部环境中的场，则可以使用该系统或外部环境中存在的物质作为场的媒介或来源。注：特别是，如果系统中有铁磁性物质，并且它们用于机械目的，则可以利用它们的磁性特性来获得额外的效果：改善部件之间的相互作用，获得有关系统状态的信息等。 ',
		case: '一种切削工具和一个从热电偶上被切削的物体，该物体可用于测量切削温度。'
	},
	{
		main: 5,
		sub: 3,
		tail: 1,
		text: '相态变化',
		tooltip: '一种物质在不引入其他物质的情况下，通过改变其相态可以提高其使用效率',
		case: '矿井中的气动系统使用液化气体而不是压缩气体'
	},
	{
		main: 5,
		sub: 3,
		tail: 2,
		text: '从一个相态转换到另一个相态',
		tooltip: '“双重”特性是通过使用能够根据操作条件从一个相态转换到另一个相态的物质来实现的 ',
		case: '一种热交换器，包括连接到交换器主体上并由形状记忆合金制成的翼片。在正常操作条件下，翼片关闭。当温度升高时，翼片打开，从而增大冷却面积。'
	},
	{
		main: 5,
		sub: 3,
		tail: 3,
		text: '使用伴随相变的物理现象',
		tooltip: '利用伴随相变的物理现象可以提高系统的效率。 注：在所有类型的相变过程中，物质的结构、密度、热导率等也随着聚集态的变化而变化。此外，在相变过程中，能量可能被释放或吸收 ',
		case: '水在冻结相（冰）中的膨胀可以用来产生压力以压碎岩石。'
	},
	{
		main: 5,
		sub: 3,
		tail: 4,
		text: '物质的双相态',
		tooltip: '系统的“双重”特性是通过将物质的单相态替换为双相态而提供的。 ',
		case: '抛光硬表面的一种介质是包含熔融的铅和铁磁性研磨颗粒'
	},
	{
		main: 5,
		sub: 3,
		tail: 5,
		text: '在系统组件之间引入相互作用',
		tooltip: '通过在系统的各部分（相）之间引入相互作用（物理或化学），可以通过用双相态代替物质的单相态而提高获得的系统效率 ',
		case: '在能源装置中使用化学反应物质。'
	},
	{
		main: 5,
		sub: 4,
		tail: 1,
		text: '使用可逆的物理变换',
		tooltip: '如果一个物体在不同的物理状态之间是交替的，那么这种转变是由物体本身通过可逆的物理变换来完成的，例如相变、电离/复合、解离/结合等注：提供过程本身自我调整的动态平衡或者在双相状态下保持稳定 ',
		case: '避雷针是用中空玻璃管填充中性气体制成的。当雷击时，气体离子化并变得导电。随后，离子重新结合，形成中性气体。'
	},
	{
		main: 5,
		sub: 4,
		tail: 2,
		text: '使用储能',
		tooltip: '如果有必要在系统的输出端处获得强效应，在输入端给予微弱影响，则将变压器物质置于接近临界状态。能量储存在物质中，并且输入信号作为触发器。 ',
		case: '为了测试空心物体的密封质量，将物体放入脱气液体中。物体内部的压力增加，如果密封不可靠，物体周围会形成气泡。为了提高观测效率，预先对液体进行过热处理'
	},
	{
		main: 5,
		sub: 5,
		tail: 1,
		text: '通过分解获得物质粒子',
		tooltip: '如果需要物质粒子（如离子）来解决问题，而根据问题条件，它们不可用，则可以通过分解更高结构层次的物质（例如，分子）来获得所需粒子',
		case: '"为了使用氢气产生高压，将产生氢气的物质放置在密封容器中进行电解。结果，产生游离氢"'
	},
	{
		main: 5,
		sub: 5,
		tail: 2,
		text: '通过组合获得物质粒子',
		tooltip: '如果需要物质粒子（例如分子）来解决问题，并且它们不能通过分解具有更高结构层次的物质来产生，则可以通过组合具有较低结构层次的粒子（例如离子）来获得所需的粒子',
		case: '为了降低容器的流体动力学阻力，可以使用高分子聚合物复合材料。然而，这种方法需要浪费大量的聚合物。有人提出在电磁场下产生水分子的复合物。'
	},
]
export const obj_field_level_6 = [{
		main: 1,
		sub: 1,
		tail: 8,
		dep: 1,
		text: '通过最大化场和保护物质提供选择性影响',
		tooltip: '如果需要选择性的作用影响（在某些区域最大，而在其他区域保持最小），则场必须是最大；然后在需要最小影响的地方引入保护物质。',
		case: '当用液体药物密封玻璃安瓿时，过热的玻璃（Smax）可能会破坏药物（Smin）。将安瓿放入水中（S2），使安瓿的尖端在水上方。水保护安瓿的其余部分不会过热。'
	},
	{
		main: 1,
		sub: 1,
		tail: 8,
		dep: 2,
		text: '通过最小化场和活性物质提供选择性影响',
		tooltip: '如果一个选择性的最大影响是必须的（在某些区域最大，在其他区域保持最小），则场应该是最小的；然后，在需要最大影响的地方引入与场相互作用产生局部效应的物质',
		case: '为了焊接两个金属部件，在部件之间引入产生额外热量的放热粉末。'
	},
	{
		main: 2,
		sub: 4,
		tail: 1,
		dep: 0,
		text: '匹配铁磁物质-场系统的节奏',
		tooltip: '通过匹配系统各元素的节奏，可以提高物-场系统的效率。',
		case: '为了改善物料的振动－磁分离过程，应使磁场与振动频率同步反向。这导致颗粒之间的粘附力降低。'
	},
	{
		main: 2,
		sub: 4,
		tail: 1,
		dep: 1,
		text: '使用电流变场（电场）',
		tooltip: '"电场物-场系统是电流相互作用的物-场系统。电场物-场系统的进化重复了复杂增强物质-场系统的进化路线：■ 简单电场物-场系统■ 复杂电场物-场系统■ 外部环境中的电场物-场系统■ 电场系统动态化■ 结构化■ 配合节奏"',
		case: '一种破碎岩石的方法，将两条导线彼此平行放置，同时把脉冲电流通过导线。'
	},
	{
		main: 2,
		sub: 4,
		tail: 1,
		dep: 2,
		text: '使用电流变流体',
		tooltip: '"如果不能使用磁性流体，则使用电流变流体（例如，在甲苯中的细石英粉末悬浮液，其粘度通过使用电场而改变）。含电流变流体的物-场系统是电场物-场系统的一种特殊形式。"',
		case: '为了提高用于提升易碎的不规则形状的零件（S1）的机器人抓手（S2）的效率，将抓手制成填充有铁磁性粉末（SF）的柔性容器。在环绕放置在必须被提升的零件周围之后，电磁场（F 磁）被施加到粉末上。因此，粉末颗粒相互挤压，并提供抓手所需的刚度。'
	},
	{
		main: 5,
		sub: 1,
		tail: 1,
		dep: 1,
		text: '如果有必要在系统中引入一种物质，但这是不允许的，则可以使用“空”代替该物质。注：“空”通常是气体物质，如空气或在固体物体中形成的空腔。在某些情况下，“空”可由其它物质形成，例如液体（泡沫）或松散的物体。',
	},
	{
		main: 5,
		sub: 1,
		tail: 1,
		dep: 2,
		text: '如果必须在系统中引入一种物质，但又不允许，则可以引入一个场来代替该物质。',
	},
	{
		main: 5,
		sub: 1,
		tail: 1,
		dep: 3,
		text: '如果需要在系统中引入某种物质，但又不允许，则可以使用外部添加剂代替内部添加剂。',
	},
	{
		main: 5,
		sub: 1,
		tail: 1,
		dep: 4,
		text: '如果有必要在系统中引入物质，而这是不允许的，则可以少量引入活性非常好的添加剂。',
	},
	{
		main: 5,
		sub: 1,
		tail: 1,
		dep: 5,
		text: '如果有必要在系统中引入物质，而这是不允许的，则可以非常少量地引入添加剂，并将其集中在物体的某些部分。',
	},
	{
		main: 5,
		sub: 1,
		tail: 1,
		dep: 6,
		text: '如果有必要在系统中引入物质，而这是不允许，则可以暂时引入添加剂，然后去除。',
	},
	{
		main: 5,
		sub: 1,
		tail: 1,
		dep: 7,
		text: '如果有必要在系统中引入一种物质，但不允许引入，则可以使用该物体的复制品来代替该物体本身，在允许引入物质的地方。',
	},
	{
		main: 5,
		sub: 1,
		tail: 1,
		dep: 8,
		text: '有必要在系统中引入物质，并且系统的操作条件不允许引入物质，则该物质可以以化合物的形式引入，该化合物可以在以后分解。',
	},
	{
		main: 5,
		sub: 1,
		tail: 1,
		dep: 9,
		text: '如果需要在系统中引入一种物质，但又是不允许的，则可以通过分解外部环境或对象或外部环境的一部分的聚集状态来产生该物质。',
	},
]